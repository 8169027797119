var __WEBPACK_NAMESPACE_OBJECT__ = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "page-editor-ui": "static-1.25124",
      "ai-components-ui-library": "static-1.2479",
      "apollo-dlb": "static-3.41",
      "apollo-link-hub-http": "static-2.1513",
      "apollo-stack-hubspot": "static-3.43",
      "atom": "static-1.2226",
      "autolinker": "static-3.11",
      "automation-applications-service-types": "static-1.593",
      "automation-ui-source-apps": "static-1.9144",
      "automation-ui-types": "static-1.10062",
      "backbone": "static-1.6",
      "blueimp-md5": "static-1.8",
      "browser-eslint": "static-2.9",
      "browserslist-config-hubspot": "static-1.127",
      "campaigns-lib": "static-1.17469",
      "chatspot-client-types": "static-1.5398",
      "chatspot-core": "static-1.8998",
      "chatspot-high-volume-service-types": "static-1.62",
      "chatspot-widget-iframe": "static-1.8996",
      "cms-field-types": "static-1.1579",
      "codemirror-dlb": "static-1.46303",
      "collaboration-sidebar": "static-1.44848",
      "commerce-analytics-service-client": "static-1.845",
      "conditional-properties-service-client": "static-1.1342",
      "content-assistance-lib": "static-1.3652",
      "content-embed-lib": "static-1.3275",
      "content-icons-ui": "static-1.1776",
      "content-intel-editor-optimize-panel": "static-1.4925",
      "content-intel-shared": "static-1.4925",
      "content-management-data-lib": "static-1.1824",
      "ContentComponents": "static-2.59854",
      "ContentData": "static-1.56972",
      "ContentEditorUI": "static-2.64753",
      "ContentUtils": "static-1.56892",
      "copilot-plugins": "static-1.1294",
      "copilot-toolkit": "static-1.1321",
      "crm-links": "static-1.2667",
      "crm-message-bus": "static-1.29073",
      "crm-pipelines-api-client-types": "static-1.1188",
      "cropperjs": "static-1.10",
      "csstype": "static-1.8",
      "cssUtils": "static-1.312",
      "cta-picker": "static-1.26503",
      "customer-data-objects": "static-1.4328",
      "customer-data-objects-ui-components": "static-1.9404",
      "customer-data-properties": "static-1.38688",
      "customer-data-property-utils": "static-1.4568",
      "customer-data-reference-ui-components": "static-1.9497",
      "customer-data-tracking": "static-1.3640",
      "customer-data-ui-utilities": "static-1.6519",
      "data-fetching-client": "static-1.5647",
      "data-token-picker": "static-1.10582",
      "data-token-views": "static-1.10566",
      "diff-match-patch": "static-1.7",
      "editor-components": "static-1.8677",
      "editor-iframe-lib": "static-1.4982",
      "EmailComponents": "static-1.41375",
      "EmailData": "static-1.40048",
      "emoji-mart": "static-3.4",
      "emoji-regex": "static-1.7",
      "enviro": "static-4.254",
      "error-messaging-js": "static-1.2148",
      "ExportDialog": "static-6.6562",
      "external-options-client-types": "static-1.1288",
      "fast-json-stable-stringify": "static-1.4",
      "feature-store-service-types": "static-1.1611",
      "feedback-loader": "static-1.22797",
      "file-manager-components": "static-1.12556",
      "FileManagerCore": "static-1.28057",
      "FileManagerImages": "static-1.25707",
      "FileManagerLib": "static-1.28201",
      "FireAlarmUi": "static-1.2065",
      "floating-ui": "static-1.5",
      "form-picker": "static-1.32277",
      "forms-utils-lib": "static-1.6893",
      "FormsCommonComponents": "static-1.74850",
      "foundations-assets": "static-1.1530",
      "foundations-components": "static-1.2735",
      "foundations-theming": "static-1.637",
      "framer-motion": "static-1.22",
      "framework-builder-read-service-client": "static-1.1273",
      "framework-data-schema-quick-fetch": "static-1.2961",
      "framework-data-schema-resolvers": "static-1.3058",
      "frontend-preferences-client": "static-1.4185",
      "getting-started-shared-tasks": "static-1.5349",
      "graphql": "static-1.31",
      "growth-data-modal": "static-1.1734",
      "growth-onboarding-shared-components": "static-1.16522",
      "growth-payments-core": "static-1.15823",
      "head-dlb": "static-1.1750",
      "HeadJS": "static-2.479",
      "history": "static-4.15",
      "hs-lodash": "static-4.9",
      "hs-promise-utils": "static-1.2165",
      "hs-story-utils": "static-1.5435",
      "hs-test-utils": "static-1.4510",
      "hub-http": "static-1.2732",
      "hub-http-contrib": "static-1.520",
      "hub-http-janus": "static-1.542",
      "hub-http-rxjs": "static-1.510",
      "hub-http-shared-msw-handlers": "static-1.4102",
      "hubspot-apollo-client": "static-1.1521",
      "hubspot-dlb": "static-1.1578",
      "hubspot-url-utils": "static-1.1203",
      "hubspotter-http": "static-1.1861",
      "HubStyle": "static-2.8354",
      "HubStyleTokens": "static-2.8044",
      "I18n": "static-7.1232",
      "i18n-data": "static-1.179",
      "icons": "static-2.581",
      "immer": "static-1.33",
      "inbound-db-meta-service-client": "static-1.1049",
      "inbound-db-properties-service-client": "static-1.1301",
      "InboxConnectUtils": "static-4.7461",
      "InpageEditorUI": "static-1.73202",
      "integrations-error-boundary-lib": "static-1.4004",
      "integrations-lib": "static-1.6592",
      "interframe": "static-3.1384",
      "jasmine": "static-4.1202",
      "jasmine-immutable": "static-1.316",
      "jasmine-runner": "static-1.3014",
      "jquery": "static-3.6",
      "laboratory-lib": "static-3.3496",
      "layout-data-lib": "static-1.5234",
      "layout-dnd-components": "static-1.7935",
      "layout-dnd-utils": "static-1.6910",
      "marketing-contacts-lib": "static-1.5306",
      "marketing-email-service-types": "static-1.703",
      "marketing-platform-lib": "static-1.3249",
      "media-bridge-lib": "static-1.13225",
      "merchandise-lib": "static-1.199",
      "messaging-editing-lib": "static-1.32705",
      "messaging-types-lib": "static-1.32437",
      "metrics-js": "static-1.5766",
      "moment-timezone": "static-5.17",
      "msw": "static-1.39",
      "object-builder-ui-client": "static-1.33698",
      "onboarding-tours": "static-1.10489",
      "onboarding-tours-client": "static-1.1350",
      "onboarding-utils": "static-1.1973",
      "outpost": "static-1.1284",
      "PatternValidationJS": "static-1.437",
      "payment-link-components": "static-1.11882",
      "payments-embed-points-lib": "static-1.5018",
      "payments-post-enroll-local-storage-lib": "static-1.1802",
      "permission-components": "static-1.2099",
      "persist-promise": "static-1.597",
      "pictos": "static-7.145",
      "pipeline-validation-client-types": "static-1.1250",
      "PortalIdParser": "static-2.233",
      "products-ui-components": "static-1.29996",
      "property-translator": "static-1.2818",
      "quartz": "static-1.3277",
      "quartz-core": "static-1.2496",
      "quartz-core-utils": "static-1.144",
      "quick-fetch": "static-1.699",
      "raven": "static-3.2013",
      "react": "static-7.133",
      "react-aria": "static-1.7",
      "react-beautiful-dnd": "static-1.13",
      "react-colorpicker": "static-2.6950",
      "react-dnd": "static-4.38",
      "react-dnd-compat": "static-1.820",
      "react-dnd-html5-backend": "static-4.5",
      "react-dnd-ipeui-backend": "static-1.417",
      "react-flip-move": "static-1.9",
      "react-immutable-proptypes": "static-2.8",
      "react-immutable-render-mixin": "static-1.9",
      "react-input-autosize": "static-2.17",
      "react-redux": "static-7.16",
      "react-rhumb": "static-1.11707",
      "react-router-dom": "static-5.26",
      "react-router-redux": "static-2.17",
      "react-select-plus": "static-1.65",
      "react-tinymce": "static-1.44456",
      "react-transition-group": "static-1.7",
      "react-utils": "static-2.2535",
      "react-virtualized": "static-2.26",
      "redux-mock-store": "static-1.8",
      "redux-request-state": "static-1.7176",
      "redux-thunk": "static-2.17",
      "redux-undo": "static-1.10",
      "ReduxMessenger": "static-2.7393",
      "reference-resolvers": "static-1.5027",
      "reference-resolvers-lite": "static-1.4593",
      "reselect": "static-2.16",
      "rich-text-lib": "static-1.14462",
      "rpc-client-utils": "static-1.1430",
      "SafeStorage": "static-1.1330",
      "sales-checkout-service-client": "static-1.857",
      "salesImages": "static-1.483",
      "sanitize": "static-1.6",
      "sanitize-text": "static-1.4979",
      "sassPrefix": "static-1.114",
      "seamless-immutable": "static-7.6",
      "self-service-api": "static-1.6868",
      "settings-ui-lib": "static-1.9148",
      "SharedI18nStrings": "static-1.157",
      "SharedLegalStrings": "static-1.1163",
      "short-messages-app-service-client": "static-1.1117",
      "simple-menus-core": "static-1.10540",
      "simple-menus-lib": "static-1.12058",
      "sinon": "static-1.9",
      "smart-content-core": "static-1.31256",
      "smart-content-lib": "static-1.31911",
      "social-composer-lib": "static-1.48406",
      "structured-content-lib": "static-1.13716",
      "StyleGuideUI": "static-3.415",
      "superstore": "static-1.1567",
      "tanstack-table": "static-1.10",
      "testing-library": "static-1.106",
      "theme-previewer-lib": "static-1.40677",
      "timezone-utils": "static-2.2237",
      "tinymce": "static-6.5",
      "tinymce-config": "static-2.43584",
      "tinymce-copilot": "static-1.5652",
      "tinymce-data": "static-1.29665",
      "tinymce-plugins": "static-1.46697",
      "tinymce-themes": "static-1.45550",
      "transmute": "static-2.29",
      "ui-active-theme-content-creator-lib": "static-1.25045",
      "ui-addon-avatars": "static-2.6293",
      "ui-addon-draggable": "static-1.4335",
      "ui-addon-emoji-picker": "static-1.6661",
      "ui-addon-form-validation": "static-1.7757",
      "ui-addon-i18n": "static-1.7580",
      "ui-addon-iframeable": "static-1.5841",
      "ui-addon-integrations-directory-panel": "static-2.4653",
      "ui-addon-opt": "static-4.5483",
      "ui-addon-react-router-dom": "static-1.6347",
      "ui-addon-sales-email": "static-1.2032",
      "ui-addon-upgrades": "static-9.12956",
      "ui-addon-video-player": "static-1.5603",
      "ui-asset-management-lib": "static-1.6094",
      "ui-business-units-lib": "static-1.5047",
      "ui-coaching-tips": "static-1.15232",
      "ui-components-test-utils": "static-1.2186",
      "ui-fonts": "static-1.325",
      "ui-gdpr-components": "static-1.6960",
      "ui-images": "static-2.733",
      "ui-memberships-settings-lib": "static-1.20719",
      "ui-product-approvals": "static-1.19099",
      "ui-shepherd-react": "static-3.6087",
      "ui-shepherd-tracker": "static-1.3468",
      "ui-suspension-banners-lib": "static-1.3168",
      "ui-tool-access": "static-1.6265",
      "ui-universal-auth": "static-1.4808",
      "UIComponents": "static-3.4995",
      "underscore": "static-1.8",
      "upgrade-management-service-types": "static-1.1602",
      "urlinator": "static-1.1417",
      "usage-tracker": "static-1.3875",
      "usage-tracker-container": "static-1.3865",
      "usage-tracker-core": "static-1.3725",
      "user-context": "static-1.1868",
      "video-data-lib": "static-1.23229",
      "video-embed": "static-1.24074",
      "videoconference-integration-components": "static-1.2588",
      "web-interactives-components": "static-1.8429",
      "web-interactives-data": "static-1.8429",
      "webpack-env": "static-1.4",
      "xray-recommendation-lib": "static-1.3340",
      "classnames": "static-2.10",
      "fuzzy": "static-1.8",
      "immutable": "static-2.19",
      "nav-meta": "static-1.21519",
      "react-dom": "static-7.85",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "wootric-nps": "static-1.3818",
      "codemirror": "static-5.25",
      "react-codemirror": "static-1.7256",
      "hs-promise-rejection-tracking": "static-1.1937",
      "raven-hubspot": "static-1.2320",
      "hoist-non-react-statics": "static-3.9",
      "bend-plugin-trellis-migration": "static-1.252",
      "moment": "static-3.26"
    },
    "depPathPrefixes": {
      "page-editor-ui": "/page-editor-ui/static-1.25124",
      "ai-components-ui-library": "/ai-components-ui-library/static-1.2479",
      "apollo-dlb": "/apollo-dlb/static-3.41",
      "apollo-link-hub-http": "/apollo-link-hub-http/static-2.1513",
      "apollo-stack-hubspot": "/apollo-stack-hubspot/static-3.43",
      "atom": "/atom/static-1.2226",
      "autolinker": "/autolinker/static-3.11",
      "automation-applications-service-types": "/automation-applications-service-types/static-1.593",
      "automation-ui-source-apps": "/automation-ui-source-apps/static-1.9144",
      "automation-ui-types": "/automation-ui-types/static-1.10062",
      "backbone": "/backbone/static-1.6",
      "blueimp-md5": "/blueimp-md5/static-1.8",
      "browser-eslint": "/browser-eslint/static-2.9",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.127",
      "campaigns-lib": "/campaigns-lib/static-1.17469",
      "chatspot-client-types": "/chatspot-client-types/static-1.5398",
      "chatspot-core": "/chatspot-core/static-1.8998",
      "chatspot-high-volume-service-types": "/chatspot-high-volume-service-types/static-1.62",
      "chatspot-widget-iframe": "/chatspot-widget-iframe/static-1.8996",
      "cms-field-types": "/cms-field-types/static-1.1579",
      "codemirror-dlb": "/codemirror-dlb/static-1.46303",
      "collaboration-sidebar": "/collaboration-sidebar/static-1.44848",
      "commerce-analytics-service-client": "/commerce-analytics-service-client/static-1.845",
      "conditional-properties-service-client": "/conditional-properties-service-client/static-1.1342",
      "content-assistance-lib": "/content-assistance-lib/static-1.3652",
      "content-embed-lib": "/content-embed-lib/static-1.3275",
      "content-icons-ui": "/content-icons-ui/static-1.1776",
      "content-intel-editor-optimize-panel": "/content-intel-editor-optimize-panel/static-1.4925",
      "content-intel-shared": "/content-intel-shared/static-1.4925",
      "content-management-data-lib": "/content-management-data-lib/static-1.1824",
      "ContentComponents": "/ContentComponents/static-2.59854",
      "ContentData": "/ContentData/static-1.56972",
      "ContentEditorUI": "/ContentEditorUI/static-2.64753",
      "ContentUtils": "/ContentUtils/static-1.56892",
      "copilot-plugins": "/copilot-plugins/static-1.1294",
      "copilot-toolkit": "/copilot-toolkit/static-1.1321",
      "crm-links": "/crm-links/static-1.2667",
      "crm-message-bus": "/crm-message-bus/static-1.29073",
      "crm-pipelines-api-client-types": "/crm-pipelines-api-client-types/static-1.1188",
      "cropperjs": "/cropperjs/static-1.10",
      "csstype": "/csstype/static-1.8",
      "cssUtils": "/cssUtils/static-1.312",
      "cta-picker": "/cta-picker/static-1.26503",
      "customer-data-objects": "/customer-data-objects/static-1.4328",
      "customer-data-objects-ui-components": "/customer-data-objects-ui-components/static-1.9404",
      "customer-data-properties": "/customer-data-properties/static-1.38688",
      "customer-data-property-utils": "/customer-data-property-utils/static-1.4568",
      "customer-data-reference-ui-components": "/customer-data-reference-ui-components/static-1.9497",
      "customer-data-tracking": "/customer-data-tracking/static-1.3640",
      "customer-data-ui-utilities": "/customer-data-ui-utilities/static-1.6519",
      "data-fetching-client": "/data-fetching-client/static-1.5647",
      "data-token-picker": "/data-token-picker/static-1.10582",
      "data-token-views": "/data-token-views/static-1.10566",
      "diff-match-patch": "/diff-match-patch/static-1.7",
      "editor-components": "/editor-components/static-1.8677",
      "editor-iframe-lib": "/editor-iframe-lib/static-1.4982",
      "EmailComponents": "/EmailComponents/static-1.41375",
      "EmailData": "/EmailData/static-1.40048",
      "emoji-mart": "/emoji-mart/static-3.4",
      "emoji-regex": "/emoji-regex/static-1.7",
      "enviro": "/enviro/static-4.254",
      "error-messaging-js": "/error-messaging-js/static-1.2148",
      "ExportDialog": "/ExportDialog/static-6.6562",
      "external-options-client-types": "/external-options-client-types/static-1.1288",
      "fast-json-stable-stringify": "/fast-json-stable-stringify/static-1.4",
      "feature-store-service-types": "/feature-store-service-types/static-1.1611",
      "feedback-loader": "/feedback-loader/static-1.22797",
      "file-manager-components": "/file-manager-components/static-1.12556",
      "FileManagerCore": "/FileManagerCore/static-1.28057",
      "FileManagerImages": "/FileManagerImages/static-1.25707",
      "FileManagerLib": "/FileManagerLib/static-1.28201",
      "FireAlarmUi": "/FireAlarmUi/static-1.2065",
      "floating-ui": "/floating-ui/static-1.5",
      "form-picker": "/form-picker/static-1.32277",
      "forms-utils-lib": "/forms-utils-lib/static-1.6893",
      "FormsCommonComponents": "/FormsCommonComponents/static-1.74850",
      "foundations-assets": "/foundations-assets/static-1.1530",
      "foundations-components": "/foundations-components/static-1.2735",
      "foundations-theming": "/foundations-theming/static-1.637",
      "framer-motion": "/framer-motion/static-1.22",
      "framework-builder-read-service-client": "/framework-builder-read-service-client/static-1.1273",
      "framework-data-schema-quick-fetch": "/framework-data-schema-quick-fetch/static-1.2961",
      "framework-data-schema-resolvers": "/framework-data-schema-resolvers/static-1.3058",
      "frontend-preferences-client": "/frontend-preferences-client/static-1.4185",
      "getting-started-shared-tasks": "/getting-started-shared-tasks/static-1.5349",
      "graphql": "/graphql/static-1.31",
      "growth-data-modal": "/growth-data-modal/static-1.1734",
      "growth-onboarding-shared-components": "/growth-onboarding-shared-components/static-1.16522",
      "growth-payments-core": "/growth-payments-core/static-1.15823",
      "head-dlb": "/head-dlb/static-1.1750",
      "HeadJS": "/HeadJS/static-2.479",
      "history": "/history/static-4.15",
      "hs-lodash": "/hs-lodash/static-4.9",
      "hs-promise-utils": "/hs-promise-utils/static-1.2165",
      "hs-story-utils": "/hs-story-utils/static-1.5435",
      "hs-test-utils": "/hs-test-utils/static-1.4510",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-contrib": "/hub-http-contrib/static-1.520",
      "hub-http-janus": "/hub-http-janus/static-1.542",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.510",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.4102",
      "hubspot-apollo-client": "/hubspot-apollo-client/static-1.1521",
      "hubspot-dlb": "/hubspot-dlb/static-1.1578",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1203",
      "hubspotter-http": "/hubspotter-http/static-1.1861",
      "HubStyle": "/HubStyle/static-2.8354",
      "HubStyleTokens": "/HubStyleTokens/static-2.8044",
      "I18n": "/I18n/static-7.1232",
      "i18n-data": "/i18n-data/static-1.179",
      "icons": "/icons/static-2.581",
      "immer": "/immer/static-1.33",
      "inbound-db-meta-service-client": "/inbound-db-meta-service-client/static-1.1049",
      "inbound-db-properties-service-client": "/inbound-db-properties-service-client/static-1.1301",
      "InboxConnectUtils": "/InboxConnectUtils/static-4.7461",
      "InpageEditorUI": "/InpageEditorUI/static-1.73202",
      "integrations-error-boundary-lib": "/integrations-error-boundary-lib/static-1.4004",
      "integrations-lib": "/integrations-lib/static-1.6592",
      "interframe": "/interframe/static-3.1384",
      "jasmine": "/jasmine/static-4.1202",
      "jasmine-immutable": "/jasmine-immutable/static-1.316",
      "jasmine-runner": "/jasmine-runner/static-1.3014",
      "jquery": "/jquery/static-3.6",
      "laboratory-lib": "/laboratory-lib/static-3.3496",
      "layout-data-lib": "/layout-data-lib/static-1.5234",
      "layout-dnd-components": "/layout-dnd-components/static-1.7935",
      "layout-dnd-utils": "/layout-dnd-utils/static-1.6910",
      "marketing-contacts-lib": "/marketing-contacts-lib/static-1.5306",
      "marketing-email-service-types": "/marketing-email-service-types/static-1.703",
      "marketing-platform-lib": "/marketing-platform-lib/static-1.3249",
      "media-bridge-lib": "/media-bridge-lib/static-1.13225",
      "merchandise-lib": "/merchandise-lib/static-1.199",
      "messaging-editing-lib": "/messaging-editing-lib/static-1.32705",
      "messaging-types-lib": "/messaging-types-lib/static-1.32437",
      "metrics-js": "/metrics-js/static-1.5766",
      "moment-timezone": "/moment-timezone/static-5.17",
      "msw": "/msw/static-1.39",
      "object-builder-ui-client": "/object-builder-ui-client/static-1.33698",
      "onboarding-tours": "/onboarding-tours/static-1.10489",
      "onboarding-tours-client": "/onboarding-tours-client/static-1.1350",
      "onboarding-utils": "/onboarding-utils/static-1.1973",
      "outpost": "/outpost/static-1.1284",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "payment-link-components": "/payment-link-components/static-1.11882",
      "payments-embed-points-lib": "/payments-embed-points-lib/static-1.5018",
      "payments-post-enroll-local-storage-lib": "/payments-post-enroll-local-storage-lib/static-1.1802",
      "permission-components": "/permission-components/static-1.2099",
      "persist-promise": "/persist-promise/static-1.597",
      "pictos": "/pictos/static-7.145",
      "pipeline-validation-client-types": "/pipeline-validation-client-types/static-1.1250",
      "PortalIdParser": "/PortalIdParser/static-2.233",
      "products-ui-components": "/products-ui-components/static-1.29996",
      "property-translator": "/property-translator/static-1.2818",
      "quartz": "/quartz/static-1.3277",
      "quartz-core": "/quartz-core/static-1.2496",
      "quartz-core-utils": "/quartz-core-utils/static-1.144",
      "quick-fetch": "/quick-fetch/static-1.699",
      "raven": "/raven/static-3.2013",
      "react": "/react/static-7.133",
      "react-aria": "/react-aria/static-1.7",
      "react-beautiful-dnd": "/react-beautiful-dnd/static-1.13",
      "react-colorpicker": "/react-colorpicker/static-2.6950",
      "react-dnd": "/react-dnd/static-4.38",
      "react-dnd-compat": "/react-dnd-compat/static-1.820",
      "react-dnd-html5-backend": "/react-dnd-html5-backend/static-4.5",
      "react-dnd-ipeui-backend": "/react-dnd-ipeui-backend/static-1.417",
      "react-flip-move": "/react-flip-move/static-1.9",
      "react-immutable-proptypes": "/react-immutable-proptypes/static-2.8",
      "react-immutable-render-mixin": "/react-immutable-render-mixin/static-1.9",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "react-redux": "/react-redux/static-7.16",
      "react-rhumb": "/react-rhumb/static-1.11707",
      "react-router-dom": "/react-router-dom/static-5.26",
      "react-router-redux": "/react-router-redux/static-2.17",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-tinymce": "/react-tinymce/static-1.44456",
      "react-transition-group": "/react-transition-group/static-1.7",
      "react-utils": "/react-utils/static-2.2535",
      "react-virtualized": "/react-virtualized/static-2.26",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-request-state": "/redux-request-state/static-1.7176",
      "redux-thunk": "/redux-thunk/static-2.17",
      "redux-undo": "/redux-undo/static-1.10",
      "ReduxMessenger": "/ReduxMessenger/static-2.7393",
      "reference-resolvers": "/reference-resolvers/static-1.5027",
      "reference-resolvers-lite": "/reference-resolvers-lite/static-1.4593",
      "reselect": "/reselect/static-2.16",
      "rich-text-lib": "/rich-text-lib/static-1.14462",
      "rpc-client-utils": "/rpc-client-utils/static-1.1430",
      "SafeStorage": "/SafeStorage/static-1.1330",
      "sales-checkout-service-client": "/sales-checkout-service-client/static-1.857",
      "salesImages": "/salesImages/static-1.483",
      "sanitize": "/sanitize/static-1.6",
      "sanitize-text": "/sanitize-text/static-1.4979",
      "sassPrefix": "/sassPrefix/static-1.114",
      "seamless-immutable": "/seamless-immutable/static-7.6",
      "self-service-api": "/self-service-api/static-1.6868",
      "settings-ui-lib": "/settings-ui-lib/static-1.9148",
      "SharedI18nStrings": "/SharedI18nStrings/static-1.157",
      "SharedLegalStrings": "/SharedLegalStrings/static-1.1163",
      "short-messages-app-service-client": "/short-messages-app-service-client/static-1.1117",
      "simple-menus-core": "/simple-menus-core/static-1.10540",
      "simple-menus-lib": "/simple-menus-lib/static-1.12058",
      "sinon": "/sinon/static-1.9",
      "smart-content-core": "/smart-content-core/static-1.31256",
      "smart-content-lib": "/smart-content-lib/static-1.31911",
      "social-composer-lib": "/social-composer-lib/static-1.48406",
      "structured-content-lib": "/structured-content-lib/static-1.13716",
      "StyleGuideUI": "/StyleGuideUI/static-3.415",
      "superstore": "/superstore/static-1.1567",
      "tanstack-table": "/tanstack-table/static-1.10",
      "testing-library": "/testing-library/static-1.106",
      "theme-previewer-lib": "/theme-previewer-lib/static-1.40677",
      "timezone-utils": "/timezone-utils/static-2.2237",
      "tinymce": "/tinymce/static-6.5",
      "tinymce-config": "/tinymce-config/static-2.43584",
      "tinymce-copilot": "/tinymce-copilot/static-1.5652",
      "tinymce-data": "/tinymce-data/static-1.29665",
      "tinymce-plugins": "/tinymce-plugins/static-1.46697",
      "tinymce-themes": "/tinymce-themes/static-1.45550",
      "transmute": "/transmute/static-2.29",
      "ui-active-theme-content-creator-lib": "/ui-active-theme-content-creator-lib/static-1.25045",
      "ui-addon-avatars": "/ui-addon-avatars/static-2.6293",
      "ui-addon-draggable": "/ui-addon-draggable/static-1.4335",
      "ui-addon-emoji-picker": "/ui-addon-emoji-picker/static-1.6661",
      "ui-addon-form-validation": "/ui-addon-form-validation/static-1.7757",
      "ui-addon-i18n": "/ui-addon-i18n/static-1.7580",
      "ui-addon-iframeable": "/ui-addon-iframeable/static-1.5841",
      "ui-addon-integrations-directory-panel": "/ui-addon-integrations-directory-panel/static-2.4653",
      "ui-addon-opt": "/ui-addon-opt/static-4.5483",
      "ui-addon-react-router-dom": "/ui-addon-react-router-dom/static-1.6347",
      "ui-addon-sales-email": "/ui-addon-sales-email/static-1.2032",
      "ui-addon-upgrades": "/ui-addon-upgrades/static-9.12956",
      "ui-addon-video-player": "/ui-addon-video-player/static-1.5603",
      "ui-asset-management-lib": "/ui-asset-management-lib/static-1.6094",
      "ui-business-units-lib": "/ui-business-units-lib/static-1.5047",
      "ui-coaching-tips": "/ui-coaching-tips/static-1.15232",
      "ui-components-test-utils": "/ui-components-test-utils/static-1.2186",
      "ui-fonts": "/ui-fonts/static-1.325",
      "ui-gdpr-components": "/ui-gdpr-components/static-1.6960",
      "ui-images": "/ui-images/static-2.733",
      "ui-memberships-settings-lib": "/ui-memberships-settings-lib/static-1.20719",
      "ui-product-approvals": "/ui-product-approvals/static-1.19099",
      "ui-shepherd-react": "/ui-shepherd-react/static-3.6087",
      "ui-shepherd-tracker": "/ui-shepherd-tracker/static-1.3468",
      "ui-suspension-banners-lib": "/ui-suspension-banners-lib/static-1.3168",
      "ui-tool-access": "/ui-tool-access/static-1.6265",
      "ui-universal-auth": "/ui-universal-auth/static-1.4808",
      "UIComponents": "/UIComponents/static-3.4995",
      "underscore": "/underscore/static-1.8",
      "upgrade-management-service-types": "/upgrade-management-service-types/static-1.1602",
      "urlinator": "/urlinator/static-1.1417",
      "usage-tracker": "/usage-tracker/static-1.3875",
      "usage-tracker-container": "/usage-tracker-container/static-1.3865",
      "usage-tracker-core": "/usage-tracker-core/static-1.3725",
      "user-context": "/user-context/static-1.1868",
      "video-data-lib": "/video-data-lib/static-1.23229",
      "video-embed": "/video-embed/static-1.24074",
      "videoconference-integration-components": "/videoconference-integration-components/static-1.2588",
      "web-interactives-components": "/web-interactives-components/static-1.8429",
      "web-interactives-data": "/web-interactives-data/static-1.8429",
      "webpack-env": "/webpack-env/static-1.4",
      "xray-recommendation-lib": "/xray-recommendation-lib/static-1.3340",
      "classnames": "/classnames/static-2.10",
      "fuzzy": "/fuzzy/static-1.8",
      "immutable": "/immutable/static-2.19",
      "nav-meta": "/nav-meta/static-1.21519",
      "react-dom": "/react-dom/static-7.85",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "wootric-nps": "/wootric-nps/static-1.3818",
      "codemirror": "/codemirror/static-5.25",
      "react-codemirror": "/react-codemirror/static-1.7256",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.1937",
      "raven-hubspot": "/raven-hubspot/static-1.2320",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.252",
      "moment": "/moment/static-3.26"
    },
    "project": "page-editor-ui",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};